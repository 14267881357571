<template>

  <v-container id="regular-tables-view" fluid tag="section">
    <v-alert
      v-if="alert.visable"
      color="primary"
      dark
      icon="fa-regular fa-hexagon-exclamation"
      border="left"
      prominent
    >
      {{ alert.description }}
    </v-alert>

    <view-intro class="text-start" heading="Tijdsblok informatie"/>


    <div v-if="loading" class="d-flex justify-content-center" style="margin-top: 30vh;">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>

    <div v-else>
      <div class="mb-4">
        <v-btn
          color="primary"
          elevation="2"
          class=" mt-4"
          outlined
          @click="$router.go(-1)"
        >&lArr; Terug
        </v-btn>
        <v-btn
          v-if="timeBlock.status == 'removed'"
          class="ml-4 mt-4"
          elevation="2"
          color="warning"
          outlined
          @click="setAsOpen"
        >
          <i class="fa-solid fa-arrow-rotate-left fa-xl mr-2"></i>
          Actief zetten
        </v-btn>
        <div class="d-inline" v-else>
<!--          <v-btn-->
<!--            v-if="timeBlock.status !== 'finished'"-->
<!--            class="ml-4 mt-4"-->
<!--            elevation="2"-->
<!--            color="green"-->
<!--            @click="setAsFinished"-->
<!--            outlined-->
<!--          >-->
<!--            <i class="fa-regular fa-check fa-xl mr-2"></i>-->
<!--            Gedaan-->
<!--          </v-btn>-->
<!--          <v-btn-->
<!--            v-if="timeBlock.status !== 'open' "-->
<!--            class="ml-4 mt-4"-->
<!--            elevation="2"-->
<!--            color="warning"-->
<!--            @click="setAsOpen"-->
<!--            outlined-->
<!--          >-->
<!--            <i class="fa-solid fa-arrow-rotate-left fa-xl mr-2"></i>-->
<!--            niet gedaan maken-->
<!--          </v-btn>-->
          <v-btn
            class="ml-4 mt-4"
            elevation="2"
            icon
            color="red"
            outlined
            @click="removeOrSetAsRemoved"
          >
            <i class="fa-regular fa-trash-alt fa-xl"></i>
          </v-btn>
        </div>
      </div>
      <material-card
        class="mt-10 elevation-1"
        icon="fa-regular fa-clock"
        icon-small
        :title="`Tijdsblok informatie`"
        color="accent"
      >
        <v-card-text>
          <v-alert
            outlined
            type="success"
            color="primary"
            v-if="timeBlock.type  === 'weekly'"
          >Elke {{ getDay(timeBlock.start) }} van {{ formatTime(timeBlock.start) }} tot
            {{ formatTime(timeBlock.end) }}
          </v-alert>
          <v-alert
            outlined
            shaped
            text
            type="warning"
            v-if="timeBlock.status === 'open'"
          >Deze bestellingen moeten voor {{ formatTime(timeBlock.end) }} afgerond zijn
          </v-alert>
          <v-alert
            outlined
            shaped
            text
            v-else-if="timeBlock.status === 'finished'"
            type="success"
          >Deze tijdsblok is afgerond
          </v-alert>
          <v-alert
            outlined
            shaped
            text
            v-else-if="timeBlock.status === 'removed'"
            type="error"
          >Deze tijdsblok is verwijderd
          </v-alert>
          <div style="display: flex; gap:15px; flex-wrap: wrap">
            <v-card
              max-width="344"
              outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 mb-1" style="display: flex; align-items: center">
                    <i class="fa-regular fa-clock mr-3 fa-2x"></i>
                    Tijd
                  </v-list-item-title>
                  Deze tijdsblok geld voor {{ timeBlock.type === 'weekly' ? 'elke week' : '' }}
                  {{ getDay(timeBlock.start) }} {{ timeBlock.type === 'once' ? `(${getFullDate()})` : '' }} van
                  {{ formatTime(timeBlock.start) }} tot
                  {{ formatTime(timeBlock.end) }}
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </div>
        </v-card-text>
      </material-card>
      <material-card
        class="mt-10 elevation-1"
        icon="fa-regular fa-boxes-stacked"
        icon-small
        title="Open bestellingen"
        color="accent"
      >
        <v-data-table
          @click:row="goToOrderDetail"
          :loading="loading"
          loading-text="Laden even geduld aub"
          :headers="orderHeaders"
          :items="timeBlockOrderGroups"
          :items-per-page="10"
        ></v-data-table>
      </material-card>
      <div class="mt-4">
        <v-btn
          color="primary"
          elevation="2"
          outlined
          @click="$router.go(-1)"
        >&lArr; Terug
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'TimeBlockDetail',
    data () {
      const { timeBlockId } = this.$route.params
      this.$auth.getTimeBlock(timeBlockId).then(timeBlock => {
        if (!timeBlock.length) {
          this.$swal({
            icon: 'error',
            title: 'Deze tijdsblok is niet gevonden',
            confirmButtonText: 'Terug',
          }).then(() => {
            window.history.go(-1)
          })
          return
        }
        this.timeBlock = timeBlock[0]
        if (this.timeBlock.orderRefs.length) {
          this.$auth.getTimeBlockOrderGroups(this.timeBlock).then(timeBlockOrderGroups => {
            this.timeBlockOrderGroups = this.$filter.filterOrderGroups(timeBlockOrderGroups)
          }).catch(err => {
            this.alert.visable = true
            this.alert.description = `Er is iets mis gegaan error: ${err} Neem graag contact met beheerder`
          })
        }
      }).catch(err => {
        this.alert.visable = true
        this.alert.description = `Er is iets mis gegaan error: ${err} Neem graag contact met beheerder`
      }).finally(() => {
        this.loading = false
      })

      return {
        timeBlockId,
        timeBlock: null,
        timeBlockOrderGroups: [],
        orderHeaders: [
          {
            text: 'Aantal',
            value: 'count',
          },
          {
            text: 'Datum',
            value: 'date',
          },
          {
            text: 'Totaal prijs',
            sortable: false,
            value: 'price',
          },
          {
            text: 'Status',
            value: 'status',
          },
        ],
        loading: true,
        alert: {
          visable: false,
          description: '',
        },
      }
    },
    methods: {
      getDateTime () {
        return `${this.getDay(this.timeBlock.start)} ${this.formatTime(this.timeBlock.end)} ${new Date(this.timeBlock.start.seconds * 1000).toISOString().slice(0, 10).split('-').reverse().join('-')}`
      },
      formatTime (timeBlockDate) {
        const date = new Date(timeBlockDate.seconds * 1000)
        const timeOtions = {
          hour: 'numeric',
          minute: 'numeric',
        }
        return date.toLocaleTimeString('nl-NL', timeOtions)
      },
      getDay (timeBlockDate) {
        const dateIndex = new Date(timeBlockDate.seconds * 1000).getDay()
        const dayArray = [
          'zondag',
          'maandag',
          'dinsdag',
          'woensdag',
          'donderdag',
          'vrijdag',
          'zaterdag',
        ]
        return dayArray[dateIndex]
      },
      getFullDate () {
        return new Date(this.timeBlock.start.seconds * 1000).toISOString().slice(0, 10).split('-').reverse().join(' ')
      },
      goToOrderDetail (order) {
        const {groupId} = order;
        this.$router.push(`/orders/${groupId}`)
      },
      removeOrSetAsRemoved () {
        this.$swal({
          icon: 'warning',
          title: 'Wilt u deze tijdsblok verwijderen?',
          showCancelButton: true,
          confirmButtonText: 'Verwijderen',
          cancelButtonText: 'Annuleren',
          closeOnConfirm: false,
          closeOnCancel: false,
        }).then((status) => {
          if (!status.isConfirmed) return
          this.$auth.removeOrSetAsRemoved(this.timeBlock).then(result => {
            if (result === 'removed') {
              this.$router.push('/timeblock')
            } else {
              this.updateData()
            }

            this.$swal({
              icon: 'success',
              title: 'Successvol',
              text: 'Tijdsblok is successvol verwijderd',
            })
          }).catch(err => {
            this.$swal({
              icon: 'error',
              title: 'Er is iets mis gegaan',
              text: 'Deze tijdsblok kon niet worden verwijderd',
            })
          })
        })
      },
      setAsFinished () {
        this.$swal({
          icon: 'warning',
          title: 'Wilt u deze tijdsblok als gedaan zetten?',
          showCancelButton: true,
          confirmButtonText: 'Als gedaan zetten',
          cancelButtonText: 'Annuleren',
          closeOnConfirm: false,
          closeOnCancel: false,
        }).then((status) => {
          if (!status.isConfirmed) return
          this.$auth.setAsFinished(this.timeBlock).then(result => {
            this.updateData()
            this.$swal({
              icon: 'success',
              title: 'Successvol',
              text: 'TijdsBlok is gezet als gedaan',
            })
          }).catch(err => {
            this.$swal({
              icon: 'error',
              title: 'Er is iets mis gegaan',
              text: 'Dit tijdsblok kan niet worden bijgewerkt',
            })
          })
        })
      },
      setAsOpen () {
        this.$swal({
          icon: 'warning',
          title: 'Wilt u deze tijdsblok als ongedaan zetten?',
          showCancelButton: true,
          confirmButtonText: 'Als ongedaan zetten',
          cancelButtonText: 'Annuleren',
          closeOnConfirm: false,
          closeOnCancel: false,
        }).then((status) => {
          if (!status.isConfirmed) return
          this.$auth.setAsOpen(this.timeBlock).then(result => {
            this.updateData()
            this.$swal({
              icon: 'success',
              title: 'Successvol',
              text: 'TijdsBlok is gezet als ongedaan',
            })
          }).catch((err) => {
            this.$swal({
              icon: 'error',
              title: 'Er is iets mis gegaan',
              text: 'Dit tijdsblok kan niet worden bijgewerkt',
            })
          })
        })
      },
      updateData () {
        this.$auth.getTimeBlock(this.timeBlockId).then(timeBlock => {
          if (!timeBlock.length) {
            this.$swal({
              icon: 'error',
              title: 'Deze tijdsblok is niet gevonden',
              confirmButtonText: 'Terug',
            }).then(() => {
              window.history.go(-1)
            })
            return
          }
          this.timeBlock = timeBlock[0]
        }).catch(err => {
          this.alert.visable = true
          this.alert.description = `Er is iets mis gegaan error: ${err} Neem graag contact met beheerder`
        }).finally(() => {
          this.loading = false
        })
      },
    },
  }
</script>

<style scoped>

</style>
